.login.login-6 .login-content .wizard-nav {
  padding: 0;
}
.login.login-6 .login-content .wizard-nav .wizard-steps {
  display: flex;
  align-items: center;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step {
  padding: 0.75rem 0;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  margin-bottom: 1.5rem;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
  display: flex;
  align-items: center;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step .wizard-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  width: 46px;
  height: 46px;
  border-radius: 12px;
  background-color: #F3F6F9;
  margin-right: 1rem;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-check {
  display: none;
  font-size: 1.4rem;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-number {
  font-weight: 600;
  color: #3F4254;
  font-size: 1.35rem;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step .wizard-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
  color: #181C32;
  font-weight: 600;
  font-size: 1.24rem;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-desc {
  color: #B5B5C3;
  font-size: 0.925rem;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  background-color: #C9F7F5;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon .wizard-check {
  color: #1BC5BD;
  display: inline-block;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-icon .wizard-number {
  display: none;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-title {
  color: #B5B5C3;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-desc {
  color: #D1D3E0;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  background-color: #C9F7F5;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon .wizard-check {
  color: #1BC5BD;
  display: none;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-icon .wizard-number {
  color: #1BC5BD;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-title {
  color: #181C32;
}
.login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-desc {
  color: #B5B5C3;
}
@media (min-width: 992px) {
  .login.login-6 .login-content .login-wrapper {
    width: 100%;
    max-width: 450px;
  }
  .login.login-6 .login-content .login-container {
    width: 100%;
    max-width: 700px;
  }
  .login.login-6 .login-aside.login-aside-w {
    width: 100%;
    max-width: 750px;
  }
  .login.login-6 .login-aside .login-img {
    min-height: 590px !important;
    background-size: 560px;
  }
  .login.login-6 .login-aside .signup-img {
    min-height: 700px !important;
    background-size: 700px;
  }
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .login.login-6 .login-aside {
    width: 100%;
    max-width: 500px;
  }
  .login.login-6 .login-aside.login-aside-w {
    width: 100%;
    max-width: 500px;
  }
  .login.login-6 .login-aside .login-img {
    min-height: 420px !important;
    background-size: 400px;
  }
  .login.login-6 .login-aside .signup-img {
    min-height: 500px !important;
    background-size: 500px;
  }
}
@media (max-width: 991.98px) {
  .login.login-6 .login-content .login-wrapper {
    width: 70%;
  }
  .login.login-6 .login-content .wizard-nav {
    padding: 0;
    align-content: center;
  }
  .login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step {
    margin-bottom: 0.5rem;
  }
  .login.login-6 .login-content .wizard-nav .wizard-steps .wizard-step:last-child {
    margin-bottom: 0;
  }
  .login.login-6 .login-aside {
    width: 100%;
    max-width: 100%;
  }
  .login.login-6 .login-aside .login-img {
    min-height: 500px !important;
    background-size: 450px;
  }
  .login.login-6 .login-aside .signup-img {
    min-height: 400px !important;
    background-size: 380px;
  }
}
@media (max-width: 575.98px) {
  .login.login-6 .login-content {
    width: 100%;
  }
  .login.login-6 .login-content .login-wrapper {
    width: 100%;
  }
  .login.login-6 .login-content .login-container {
    width: 100%;
    max-width: 100%;
  }
  .login.login-6 .login-content .login-form {
    width: 100%;
    max-width: 100% !important;
  }
  .login.login-6 .login-aside.login-aside-w {
    width: 100%;
    max-width: 100%;
  }
  .login.login-6 .login-aside h3 {
    font-size: 35px !important;
  }
  .login.login-6 .login-aside .login-img {
    min-height: 350px !important;
    background-size: 300px;
  }
  .login.login-6 .login-aside .signup-img {
    min-height: 320px !important;
    background-size: 300px;
  }
}